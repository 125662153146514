<template>
    <div class="flex-col padding-20 dynamicArea">
        <base-info-view ref="baseInfo" :pre-data="baseInfoPreData">
            <!--            <dynamic-view slot="dynamicForm" ref="dynamicData" :dynamic-data="formData"/>-->
        </base-info-view>
    </div>
</template>

<script>
    import BaseInfoView from "../components/baseInfo";
    import DynamicView from "../components/dynamicFormView";
    import jsonData from './test.json'
    // import notaryInfoData from './notaryInfo.json'

    export default {
        name: 'HelloWorld',
        components: {DynamicView, BaseInfoView},
        data() {
            return {
                baseInfoPreData: {
                    purpose:[],
                    territory:[],
                    trans:[],
                    notaryInfo:[]
                },
                // formData: null,
                // formData: jsonData,  //notaryInfoData
            }
        },
        props: {
            params: {
                type: Object,
                default() {
                    return {};
                },
            },
            interactionBridge: Function,
        },
        watch: {
            params: {
                handler(newValue) {
                    // 外部参数
                    console.log('动态表单组件获取到外部参数为=', newValue);
                    this.analysisData(newValue);
                },
                immediate: true
            },
        },
        mounted() {
            this.$root.$on('jqBridge', this.onBridgeConnect);
        },
        destroyed() {
            this.$root.$off('jqBridge', this.onBridgeConnect);
        },
        methods: {
            onBridgeConnect($event) {
                // 收到最上层ocr事件-传递出去
                let {event, data} = $event, param;
                switch (event) {
                    case 'doOcrNow':
                        data.dynamicData = this.formData;
                        param = {event, data}
                        break;
                    default:
                        param = $event;
                        break
                }
                this.interactionBridge(param);
            },
            analysisData(params) {
                if (this.$verify.isEmpty(params)) return;
                console.log('params=', params);
                let {purpose, territory, trans, notaryInfo, dynamicData,basicUse} = params;
                notaryInfo = notaryInfo;
                console.log("analysisData-notaryInfo=",notaryInfo);
                this.baseInfoPreData = {purpose, territory, trans, notaryInfo,basicUse};
                this.formData = dynamicData;
            },
            /**
             * 刷新所有数据
             */
            refreshDynamicData(newData) {
                if (this.$verify.isEmpty(newData)) {
                    this.onBridgeConnect({
                        event: 'log',
                        data: {
                            msg: '你传过来的数据有问题！',
                            yourData: newData
                        }
                    });
                    return;
                }
                console.log('收到数据刷新请求-newData=', newData);
                let {detail, dynamicData} = newData;
                // this.$set(this, 'formData', dynamicData);
                this.$refs['baseInfo'].refreshData(detail);
            },
            /**
             * 单独刷新动态表单
             */
            refreshDynamic(newDynamicData) {
                this.$set(this, 'formData', newDynamicData);
            },
            //刷新基础信息的表单
            refBasisInfo(basisData) {
                this.$set(this, 'baseInfoPreData', basisData);
            },
            saveFormData() {
                return this.submitBasicForm();
            },
            // submitForm() {
            //     // 准备提交数据-先进行基础数据校验
            //     if (!this.$refs['baseInfo'].verifyBaseData()) {
            //         return null;
            //     }
            //     // if (!this.$refs['dynamicData'].verifyDynamicData()) {
            //     //     return null;
            //     // }
            //     if (!this.$refs['baseInfo'].verifyGetData()) {
            //         return null;
            //     }
            //
            //     let detail = this.$refs['baseInfo'].packDetailData();
            //     let dynamicData = this.$refs['dynamicData'].packDynamicData();
            //     let backData = {detail, dynamicData}
            //     console.log('返回jq数据=', backData);
            //     return backData;
            // },
            submitBasicForm() {
                
                // 准备提交数据-先进行基础数据校验
                if (!this.$refs['baseInfo'].verifyBaseData()) {
                    return null;
                }
                if (!this.$refs['baseInfo'].verifyGetData()) {
                    return null;
                }

                let detail = this.$refs['baseInfo'].packDetailData();
                let backData = {detail}
                console.log('返回jq数据=', backData);
                return backData;
            },

            //不需要验证的数据
            DontneedBasicForm() {
                let detail = this.$refs['baseInfo'].packDetailData();
                let backData = {detail}
                console.log('返回jq数据=', backData);
                return backData;
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .dynamicArea {
        /*width: 828px;*/
    }
</style>
